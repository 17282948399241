// Types
import { ChatsActionTypes } from "../types";
// Services
import ApiService from "../../services/ApiService";
import notify from "../../services/Notifications";

const fetchMessageDeleteStart = () => ({
  type: ChatsActionTypes.FETCH_DELETE_MESSAGE_START,
});
const fetchMessageDeleteSuccess = ()=>({
  type:ChatsActionTypes.FETCH_DELETE_MESSAGE_SUCCESS
})
// Delete chat message
export const deleteMessageAsync = ({messageId,userId}) => (dispatch) => {
  dispatch(fetchMessageDeleteStart());
  ApiService.apiCall({
    url: ApiService.paths.chats.messageDelete,
    token: true,
    method: "DELETE",
    params: { messageId},
  })
    .then((response) => {
      if (!response?.response?.status) {
        notify(`Message deleted!`, "success");
        
      } else {
        notify(response.message, "warn");
      }
      dispatch(fetchMessageDeleteSuccess())
      fetchMessageListAsync(dispatch,{userId})
    })
    .catch((error) => {
      dispatch(
        fetchMessageError({
          message: "Произошла ошибка во время удаления трансляции",
        })
      );
      setTimeout(() => {
        dispatch(fetchMessageClearError());
      }, 1000);
    });
};
// Get Chat message List for a user
const fetchChatsGetSuccess = (data) => ({
  type: ChatsActionTypes.FETCH_GET_MESSAGE_LIST_SUCCESS,
  payload: data,
});

const fetchChatsGetStart = () => ({
  type: ChatsActionTypes.FETCH_GET_MESSAGE_LIST_START,
});
const fetchMessageError = () => ({
  type: ChatsActionTypes.FETCH_MESSAGE_ERROR,
});
const fetchMessageClearError = () => ({
  type: ChatsActionTypes.FETCH_CLEAR_ERROR,
});

export const fetchMessageListAsync = (
  dispatch,
  params
) => {
  dispatch(fetchChatsGetStart());

  ApiService.apiCall({
    url: ApiService.paths.chats.chatsGet,
    token: true,
    params: params,
  })
    .then((response) => {
      dispatch(
        fetchChatsGetSuccess({
          list: response?.data,
          params: params,
        })
      );
    })
    .catch((error) => {
      dispatch(fetchMessageError(error));
      setTimeout(() => {
        fetchMessageClearError();
      }, 300);
    });
};

