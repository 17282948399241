// Loading actions
import {
    fetchLoadingFinish,
    fetchLoadingStart,
    fetchLoadingError,
    fetchLoadingClearError,
  } from "./loadingAction";
  // Types
  import { EposterListActionTypes } from "../types";
  // Services
  import ApiService from "../../services/ApiService";
  import notify from "../../services/Notifications";
  // Constants
  import {
    STARTING_PAGE,
    STARTING_PAGE_LIMIT,
  } from "../../common/constants/tableConfig";
  
  // Create eposter
  export const createEposterAsync = (data, fetchParams) => (dispatch) => {
    dispatch(fetchLoadingStart());
    ApiService.apiCall({
      url: ApiService.paths.eposters.epostersCreate,
      token: true,
      method: "POST",
      data: data,
    })
      .then((response) => {
        dispatch(fetchLoadingFinish());
  
        if (!response?.response?.status) {
          notify(`Eposter created!`, "success");
          fetchEposterListGetAsync(dispatch, fetchParams);
        } else {
          notify(response.message, "warn");
        }
      })
      .catch((error) => {
        dispatch(
          fetchLoadingError({
            message: "Произошла ошибка во время создания постера",
          })
        );
        setTimeout(() => {
          dispatch(fetchLoadingClearError());
        }, 1000);
      });
  };
  // Edit qa
  export const updateEposterAsync = (data, fetchParams) => (dispatch) => {
    dispatch(fetchLoadingStart());
    ApiService.apiCall({
      url: ApiService.paths.eposters.epostersEdit,
      method: "PUT",
      token: true,
      data: data,
    })
      .then((response) => {
        dispatch(fetchLoadingFinish());
  
        if (!response?.response?.status) {
          notify(`Eposter updated!`, "success");
          fetchEposterListGetAsync(dispatch, fetchParams);
        } else {
          notify(response.message, "warn");
        }
      })
      .catch((error) => {
        dispatch(
          fetchLoadingError({
            message: "Произошла ошибка во время редактирования постера",
          })
        );
        setTimeout(() => {
          dispatch(fetchLoadingClearError());
        }, 1000);
      });
  };
  // Delete qa
  export const deleteEposterAsync = (data, fetchParams) => (dispatch) => {
    dispatch(fetchLoadingStart());
    ApiService.apiCall({
      url: ApiService.paths.eposters.epostersDelete,
      token: true,
      method: "DELETE",
      params: { id: data },
    })
      .then((response) => {
        dispatch(fetchLoadingFinish());
  
        if (!response?.response?.status) {
          notify(`Eposter deleted!`, "success");
          fetchEposterListGetAsync(dispatch, fetchParams);
        } else {
          notify(response.message, "warn");
        }
      })
      .catch((error) => {
        dispatch(
          fetchLoadingError({
            message: "Произошла ошибка во время удаления постера",
          })
        );
        setTimeout(() => {
          dispatch(fetchLoadingClearError());
        }, 1000);
      });
  };
  // Get paginated QA list - {page,limit}
  const fetchEposterListGet = (data) => ({
    type: EposterListActionTypes.FETCH_GET_EPOSTER_LIST,
    payload: data,
  });
  export const fetchEposterListGetAsync = (
    dispatch,
    params = { page: STARTING_PAGE - 1, limit: STARTING_PAGE_LIMIT }
  ) => {
    dispatch(fetchLoadingStart());
  
    ApiService.apiCall({
      url: ApiService.paths.eposters.epostersGet,
      token: true,
      params: { ...params, page: params.page + 1 },
    })
      .then((response) => {
        const { eposters, pages, total } = response?.data;
        dispatch(
          fetchEposterListGet({
            list: eposters,
            params: { limit: params.limit, pages, total },
          })
        );
        dispatch(fetchLoadingFinish());
      })
      .catch((error) => {
        dispatch(fetchLoadingError(error));
        setTimeout(() => {
          fetchLoadingClearError();
        }, 300);
      });
  };
  