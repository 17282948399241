import { SessionsListActionTypes } from "../types";
// Constants
import {
  STARTING_PAGE,
  STARTING_PAGE_LIMIT,
} from "../../common/constants/tableConfig";

const {
  FETCH_GET_SESSIONS_LIST_CLEAR_ERROR,
  FETCH_GET_SESSIONS_LIST_ERROR,
  FETCH_GET_SESSIONS_LIST_START,FETCH_GET_SESSIONS_LIST_SUCCESS,
  CLEAR_CURRENT_LIST,
} = SessionsListActionTypes;

const INIT_STATE = {
  list: [],
  params: {
    page: STARTING_PAGE,
    limit: STARTING_PAGE_LIMIT,
    total: 0,
  },
  loading: false,
  error: "",
  item: null,
};
const sessionsListReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_GET_SESSIONS_LIST_START:
      return { ...state, loading: true, error: "" };
    case FETCH_GET_SESSIONS_LIST_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        params: INIT_STATE.params,
      };
    case FETCH_GET_SESSIONS_LIST_SUCCESS:
      return {
        ...state,
        params: action.payload.params,
        list: action.payload.list || [],
        total: action.payload.total,
        loading: false,
        error: "",
      };
    case CLEAR_CURRENT_LIST:
      return { ...state, currentList: [] };
    case FETCH_GET_SESSIONS_LIST_CLEAR_ERROR:
      return { ...state, error: "" };
    default:
      return state;
  }
};

export default sessionsListReducer;
