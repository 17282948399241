// Types
import { UserListActionTypes } from "../types";
import { QaListActionTypes } from "../types";
// Actions
import * as authUser from "./authUser";
import * as userList from "./userList";
import * as qaList from './qaList'
import * as eposters from './eposters'
import * as broadcastsList from './broadcastList'
import * as programList from './programList'
import * as sessionsList from './sessionsList'
import * as partnerList from './partnerList'
import * as chats from './chats'
import * as files from './fileActions'
import * as datesList from './datesList'
import * as hallsList from './hallsList'
import * as contentsList from './contentsList'
import * as materialsList from './materialsList'

//Action Types
const {
  FETCH_GET_USER_FROM_LIST,
  FETCH_DELETE_USER_LIST,
  FETCH_GET_USER_LIST,
  FETCH_PUT_USER_LIST,
  FETCH_ADD_USER_LIST,
  FETCH_BULK_DELETE_USER_LIST,
  SORT_USER_LIST
} = UserListActionTypes;

// UserList Actions

export const fetchGetUserList = (params) => ({
  type: FETCH_GET_USER_LIST,
  payload: params,
});
export const fetchAddUserList = (data) => ({
  type: FETCH_ADD_USER_LIST,
  payload: data,
});
export const fetchGetUserFromList = (id) => ({
  type: FETCH_GET_USER_FROM_LIST,
  payload: id,
});
export const fetchUpdateUserFromList = (data) => ({
  type: FETCH_PUT_USER_LIST,
  payload: data,
});
export const fetchDeleteUserFromList = (id) => ({
  type: FETCH_DELETE_USER_LIST,
  payload: id,
});
export const fetchBulkDeleteUserList = (ids) => ({
  type: FETCH_BULK_DELETE_USER_LIST,
  payload: ids,
});
//
export const sortUserListByName = (sort, sortDirection) => ({
  type: SORT_USER_LIST,
  sort,
  sortDirection
})
//
//Action Types
const {
  FETCH_GET_QA_LIST,
  FETCH_ADD_QA_LIST,
  FETCH_PUT_QA_LIST,
  FETCH_DELETE_QA_LIST,
  FETCH_GET_QA_FROM_LIST,
  FETCH_BULK_DELETE_QA_LIST,
} = QaListActionTypes;

// QaList Actions

export const fetchGetQaList = (params) => ({
  type: FETCH_GET_QA_LIST,
  payload: params,
});
export const fetchAddQaToList = (data) => ({
  type: FETCH_ADD_QA_LIST,
  payload: data,
});
export const fetchGetQaFromList = (id) => ({
  type: FETCH_GET_QA_FROM_LIST,
  payload: id,
});
export const fetchUpdateQaFromList = (data) => ({
  type: FETCH_PUT_QA_LIST,
  payload: data,
});
export const fetchDeleteQaFromList = (id) => ({
  type: FETCH_DELETE_QA_LIST,
  payload: id,
});
export const fetchBulkDeleteQaList = (ids) => ({
  type: FETCH_BULK_DELETE_QA_LIST,
  payload: ids,
});


// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ...authUser,
  ...userList,
  ...qaList,
  ...eposters,
  ...broadcastsList,
  ...programList,
  ...partnerList,
  ...chats,
  ...sessionsList,
  ...files,
  ...datesList,
  ...hallsList,
  ...contentsList,
  ...materialsList
};
