import { DateListTypes } from "../types";

const {
  FETCH_GET_DATES_LIST_START,
  FETCH_GET_DATES_LIST_SUCCESS,
  FETCH_GET_DATES_LIST_ERROR,
  FETCH_GET_DATES_LIST_CLEAR_ERROR
} = DateListTypes;

const INIT_STATE = {
  dates: [],
  loading: false,
  error: "",
};
const datesListReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_GET_DATES_LIST_START:
      return { ...state, loading: true, error: ""};
    case FETCH_GET_DATES_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        dates: action.dates,
      };
    case FETCH_GET_DATES_LIST_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case FETCH_GET_DATES_LIST_CLEAR_ERROR:
      return { ...state, error: "" };
    default:
      return state;
  }
};

export default datesListReducer;
