import {hallTypes} from '../common/constants/selectOptions'
class GetData {
  getProgramsFromBroadcasts(broadcasts) {
    let result = [];
    broadcasts.forEach((broadcast) => {
      if (broadcast?.programs?.length > 0) {
        result = [...result, ...broadcast.programs];
      }
    });
    return result;
  }
  setSubProps = (fRow) => ({
    hallIds: hallTypes.find((hall) => hall?.text === fRow.hallId)?.value,broadCastName:fRow?.name
  });
  setMessageProps=fRow=>({
    id:fRow.id
  })
  getHallName =id=>hallTypes.find((hall) => hall?.value === id)?.text

  booleanTransformForUser=(data)=>{
    if(typeof data.participationStatus==='string'){
     return  {...data,participationStatus:data.participationStatus==="PAID",...data.hasOwnProperty('status')&&{status:data.status==="ACTIVE"}}
    }
    return {...data,participationStatus:data.participationStatus?"PAID":"UNPAID",...data.hasOwnProperty('status')&&{status:data.status?"ACTIVE":"NOT_ACTIVE"}}
    
  }
}
export default new GetData();
