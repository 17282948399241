import { UserListActionTypes } from "../types";
// Constants
import {
  STARTING_PAGE,
  STARTING_PAGE_LIMIT,
} from "../../common/constants/tableConfig";
// // Mock data
// import { MOCK } from "../../data/user_mock_data";

const {
  FETCH_GET_USER_LIST,
  SORT_USER_LIST,
  FETCH_GET_USER_EXCEL_LIST
} = UserListActionTypes;

const INIT_STATE = {
  list: [],
  params: {
    page: STARTING_PAGE,
    limit: STARTING_PAGE_LIMIT,
    total: 0,
  },
  item: null,
  listLink: null,
  sortBy: 'id',
  sortDirection: 'ASC'
};
const userListReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_GET_USER_LIST:
      return {
        ...state,
        params: action.payload.params,
        list: action.payload.list || [],
        total: action.payload.total,
        listLink: action.payload.listLink
      };
    case SORT_USER_LIST:
      return {
        ...state,
        sortBy: action.sort,
        sortDirection: action.sortDirection
      }

    case FETCH_GET_USER_EXCEL_LIST:
      return {
        ...state,
        listLink: action.userListLink
      }
    default:
      return state;
  }
};

export default userListReducer;
