// Types
import { UserActionTypes } from "../types";
// Services
import ApiService from "../../services/ApiService";
import StorageService from "../../services/StorageService";
import notify from "../../services/Notifications";

// Navigation
import routes from "../../common/routes";

const fetchLoginUserStart = () => ({
  type: UserActionTypes.FETCH_LOGIN_USER_START,
});

const fetchLoginUserSuccess = () => ({
  type: UserActionTypes.FETCH_LOGIN_USER_SUCCESS,
});

const fetchLoginUserError = (error) => ({
  type: UserActionTypes.FETCH_LOGIN_USER_ERROR,
  payload: error,
});

export const loginUserByNameAndPasswordAsync = (data,history) => (dispatch) => {
  dispatch(fetchLoginUserStart());
  ApiService.apiCall({
    url: ApiService.paths.user.LOGIN,
    method: "POST",
    data: data,
  })
    .then((response) => {
      const { data } = response;
      StorageService.setToken(data.token);
      dispatch(fetchLoginUserSuccess());
      // in case API
      fetchCurrentUserAsync(dispatch,history);
      setTimeout(() => {
        history.push(routes.app.main);
      }, 300);
    })
    .catch((error) => {
      logoutUser()
      notify("Неверная комбинация логина и пароля",'error')
      dispatch(fetchLoginUserError({ message: "Неверная комбинация логина и пароля" }));
    });
};

const fetchCurrentUserStart = () => ({
  type: UserActionTypes.FETCH_CURRENT_USER_START,
});

const fetchCurrentUserSuccess = (user) => ({
  type: UserActionTypes.FETCH_CURRENT_USER_SUCCESS,
  payload: user,
});

// const fetchCurrentUserError = (error) => ({
//   type: UserActionTypes.FETCH_CURRENT_USER_ERROR,
//   payload: error,
// });

export const fetchCurrentUserAsync = (dispatch,history) => {
  dispatch(fetchCurrentUserStart());

  ApiService.apiCall({
    url: ApiService.paths.user.CURRENT_USER,
    token: true,
  })
    .then((response) => {
      const item = response.data.currentUser;
      dispatch(fetchCurrentUserSuccess(item));
      StorageService.setSession(item); // temp value
      
    })
    .catch((error) => {
      notify('Ваша сессия завершена. Зайдите еще раз пожалуйста')
      logoutUser()
      setTimeout(() => {
        history.push(routes.app.main);
      }, 300);
      // dispatch(fetchCurrentUserError(error)); // Keep it commented out for production
    });
};

export const logoutUser = () => (dispatch) => {
  dispatch({ type: UserActionTypes.LOGOUT_USER });
  StorageService.removeToken();
  StorageService.removeUID();
  StorageService.removeSession();
  // notify('Logout Success!');
};
