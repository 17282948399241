// Types
import { LoadingTypes } from "../types";

export const fetchLoadingStart = () => ({
  type: LoadingTypes.LOADING_START,
});
export const fetchLoadingFinish = () => ({
  type: LoadingTypes.LOADING_FINISH,
});
export const fetchLoadingError = (message) => ({
  type: LoadingTypes.LOADING_ERROR,
  payload: message,
});
export const fetchLoadingClearError = ()=>({
  type:LoadingTypes.LOADING_CLEAR_ERROR
})
