// Types
import { DateListTypes } from "../types";
// Services
import ApiService from "../../services/ApiService";
import notify from "../../services/Notifications";

const fetchDatesError=(data)=>({
  type:DateListTypes.FETCH_GET_DATES_LIST_ERROR,
  payload:data
})
const fetchDatesClearError=()=>({
  type:DateListTypes.FETCH_GET_DATES_LIST_CLEAR_ERROR
})
const fetchDatesGetSuccess = (data) => ({
  type: DateListTypes.FETCH_GET_DATES_LIST_SUCCESS,
  dates: data,
});

const fetchDatesGetStart = () => ({
  type: DateListTypes.FETCH_GET_DATES_LIST_START,
});


export const createDateAsync = (data) => dispatch => {
  ApiService.apiCall({
    url: ApiService.paths.dates.dateCreate,
    token: true,
    method: "POST",
    data: data,
  })
    .then(response => {
      if (response?.data?.status) {
        notify(`Дата успешно создана!`, "success");
        fetchDateListAsync(dispatch);
      } else {
        notify(response.message, "warn");
      }
    })
    .catch((error) => {
      dispatch(
        fetchDatesError({
          message: "Произошла ошибка во время создания даты",
        })
      );
      setTimeout(() => {
        dispatch(fetchDatesClearError());
      }, 1000);
    });
}

export const fetchDateListAsync = (
  dispatch,
) => {

  dispatch(fetchDatesGetStart());

  ApiService.apiCall({
    url: ApiService.paths.dates.dateGet,
    token: true,
  })
    .then((response) => {
      if(response?.data) {
        dispatch(fetchDatesGetSuccess(response?.data))
      }
    })
    .catch((error) => {
      dispatch(fetchDatesError(error));
      setTimeout(() => {
        fetchDatesClearError();
      }, 300);
    });
};

export const deletedateAsync = (data) => dispatch => {
  ApiService.apiCall({
    url: ApiService.paths.dates.dateDelete,
    token: true,
    method: "DELETE",
    params: { dateId: data }
  })
   .then(response => {
    if (response?.data?.status) {
        notify(`Дата успешно удалена!`, "success");
        fetchDateListAsync(dispatch);
      } else {
        notify(response.message, "warn");
      }
   })
   .catch((error) => {
      dispatch(
        fetchDatesError({
          message: "Произошла ошибка во время удаления даты",
        })
      );
      setTimeout(() => {
        dispatch(fetchDatesClearError());
      }, 1000);
    });
}

export const updateDateAsync = (data) => (dispatch) => {
  ApiService.apiCall({
    url: ApiService.paths.dates.dateEdit,
    token: true,
    method: "PUT",
    data: data,
  })
    .then(response => {
      if (response?.data?.status) {
          notify(`Дата успешно отредактирована!`, "success");
          fetchDateListAsync(dispatch);
        } else {
          notify(response.message, "warn");
        }
    })
    .catch((error) => {
        dispatch(
          fetchDatesError({
            message: "Произошла ошибка во время редактирования даты",
          })
        );
        setTimeout(() => {
          dispatch(fetchDatesClearError());
        }, 1000);
      });
  };
