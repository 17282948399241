import { BroadcastListActionTypes } from "../types";
// // Mock data
// import { MOCK } from "../../data/broadcasts_mock_data";
// Constants
import {
  STARTING_PAGE,
  STARTING_PAGE_LIMIT,
} from "../../common/constants/tableConfig";

const { FETCH_GET_BROADCAST_LIST } = BroadcastListActionTypes;

const INIT_STATE = {
  // list: MOCK.data.slice(0, 6), // Insert MOCK.data - for pregenerated users
  list: [],
  params: {
    page: STARTING_PAGE,
    limit: STARTING_PAGE_LIMIT,
    total: 0,
  },
  item: null,
};
const broadcastListReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_GET_BROADCAST_LIST:
      return {
        ...state,
        params: action.payload.params,
        list: action.payload.list || [],
        total:
          action.payload.total === 1000
            ? STARTING_PAGE_LIMIT
            : action.payload.total,
      };

    default:
      return state;
  }
};

export default broadcastListReducer;
