import { ProgramListActionTypes } from "../types";
// // Mock data
// import { MOCK } from "../../data/program_mock_data";
// Constants
import {
  STARTING_PAGE,
  STARTING_PAGE_LIMIT,
} from "../../common/constants/tableConfig";

const {
  FETCH_GET_PROGRAM_LIST_CLEAR_ERROR,
  FETCH_GET_PROGRAM_LIST_ERROR,
  FETCH_GET_PROGRAM_LIST_START,
  FETCH_GET_PROGRAM_LIST_BY_SESSION_SUCCESS,
  FETCH_GET_PROGRAM_LIST_BY_HALL_SUCCESS,
  CLEAR_CURRENT_LIST,
} = ProgramListActionTypes;

const INIT_STATE = {
  // list: MOCK.data.slice(0, 6), // Insert MOCK.data - for pregenerated users
  list: [],
  currentList: [],
  params: {
    page: STARTING_PAGE,
    limit: STARTING_PAGE_LIMIT,
    total: 0,
  },
  fetching: false,
  error: "",
  item: null,
};
const programListReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_GET_PROGRAM_LIST_START:
      return { ...state, fetching: true, error: "" };
    case FETCH_GET_PROGRAM_LIST_ERROR:
      return {
        ...state,
        fetching: false,
        error: action.payload,
        currentList: [],
        params: INIT_STATE.params,
      };
    case FETCH_GET_PROGRAM_LIST_BY_SESSION_SUCCESS:
      return {
        ...state,
        params: action.payload.params,
        list: action.payload.list || [],
        total: action.payload.total,
        fetching: false,
        error: "",
      };
    case FETCH_GET_PROGRAM_LIST_BY_HALL_SUCCESS:
      return {
        ...state,
        currentList: action.payload.list,
        fetching: false,
        error: "",
      };
    case CLEAR_CURRENT_LIST:
      return { ...state, currentList: [] };
    case FETCH_GET_PROGRAM_LIST_CLEAR_ERROR:
      return { ...state, error: "" };
    default:
      return state;
  }
};

export default programListReducer;
