// Loading actions
import {
    fetchLoadingFinish,
    fetchLoadingStart,
    fetchLoadingError,
    fetchLoadingClearError,
  } from "./loadingAction";
  // Types
  import { FileTypes } from "../types";
// Services
import ApiService from "../../services/ApiService";
import notify from "../../services/Notifications";

  const fetchFilesGet = (data) => ({
    type: FileTypes.FETCH_GET_FILES_SUCCESS,
    payload: data,
  });
  export const fetchFilesGetAsync = ()=>
    dispatch=> {
    dispatch(fetchLoadingStart());
  
    ApiService.apiCall({
      url: ApiService.paths.filesGet,
      token: true,
    })
      .then((response) => {
        dispatch(
            fetchFilesGet( response?.files||[])
        );
        dispatch(fetchLoadingFinish());
      })
      .catch((error) => {
        dispatch(fetchLoadingError(error));
        setTimeout(() => {
          fetchLoadingClearError();
        }, 300);
      });
  };

  // fileName 
  // Delete file
export const deleteFileAsync = (data) => (dispatch) => {
  dispatch(fetchLoadingStart());
  ApiService.apiCall({
    url: ApiService.paths.fileDelete,
    token: true,
    method: "DELETE",
    params: { fileName: data },
  })
    .then((response) => {
      dispatch(fetchLoadingFinish());
      if (!response?.response?.status) {
        notify(`File deleted!`, "success");
        dispatch(fetchFilesGetAsync())
      } else {
        notify(response.message, "warn");
      }
    })
    .catch((error) => {
      dispatch(
        fetchLoadingError({
          message: "Произошла ошибка во время удаления вопроса",
        })
      );
      setTimeout(() => {
        dispatch(fetchLoadingClearError());
      }, 1000);
    });
};