// Loading actions
import {
  fetchLoadingFinish,
  fetchLoadingStart,
  fetchLoadingError,
  fetchLoadingClearError,
} from "./loadingAction";
// Types
import { PartnerListActionTypes } from "../types";
// Services
import ApiService from "../../services/ApiService";
import notify from "../../services/Notifications";
// Constants
import routes from "../../common/routes";
import {
  STARTING_PAGE,
  STARTING_PAGE_LIMIT,
} from "../../common/constants/tableConfig";

// Create partner
export const createPartnerAsync = (data, history) => (dispatch) => {
  dispatch(fetchLoadingStart());
  ApiService.apiCall({
    url: ApiService.paths.partnerList.partnerCreate,
    token: true,
    method: "POST",
    data: data,
  })
    .then((response) => {
      dispatch(fetchLoadingFinish());

      if (!response?.response?.status) {
        notify(`Partner created!`, "success");
        setTimeout(() => {
          history.push(routes.partnerListPage.edit + response.data.partner.id);
        }, 500);
      } else {
        notify(response.message, "warn");
      }
    })
    .catch((error) => {
      dispatch(
        fetchLoadingError({
          message: "Произошла ошибка во время создания партнера",
        })
      );
      setTimeout(() => {
        dispatch(fetchLoadingClearError());
      }, 1000);
    });
};

const fetchGetByIdPartnerStart = () => ({
  type: PartnerListActionTypes.FETCH_GET_PARTNER_BY_ID_START,
});
const fetchGetByIdPartnerSuccess = (data) => ({
  type: PartnerListActionTypes.FETCH_GET_PARTNER_BY_ID_SUCCESS,
  payload: data,
});
const fetchGetByIdPartnerError = () => ({
  type: PartnerListActionTypes.FETCH_GET_PARTNER_BY_ID_ERROR,
});
const fetchGetByIdPartnerClearError = () => ({
  type: PartnerListActionTypes.FETCH_GET_PARTNER_BY_ID_CLEAR_ERROR,
});
export const clearCurrentPartner = () => ({
  type: PartnerListActionTypes.CLEAR_CURRENT_PARTNER_DATA,
});
// Get partner by id
export const getPartnerByIdAsync = (id) => (dispatch) => {
  dispatch(fetchGetByIdPartnerStart());
  ApiService.apiCall({
    url: ApiService.paths.partnerList.partnerGetById,
    token: true,
    params: { id },
  })
    .then((response) => {
      dispatch(fetchGetByIdPartnerSuccess(response.data));
    })
    .catch((error) => {
      dispatch(
        fetchGetByIdPartnerError({
          message: "Произошла ошибка во время загрузки партнера",
        })
      );
      setTimeout(() => {
        dispatch(fetchGetByIdPartnerClearError());
      }, 1000);
    });
};

// const fetchUpdatePartnerStart = () => ({
//   type: PartnerListActionTypes.FETCH_GET_PARTNER_BY_ID_START,
// });
// const fetchUpdatePartnerSuccess = (data) => ({
//   type: PartnerListActionTypes.FETCH_GET_PARTNER_BY_ID_SUCCESS,
//   payload: data,
// });
// const fetchUpdatePartnerError = () => ({
//   type: PartnerListActionTypes.FETCH_GET_PARTNER_BY_ID_ERROR,
// });
// const fetchUpdatePartnerClearError = () => ({
//   type: PartnerListActionTypes.FETCH_GET_PARTNER_BY_ID_CLEAR_ERROR,
// });

// Update partner
export const updatePartnerAsync = (data) => (dispatch) => {
  ApiService.apiCall({
    url: ApiService.paths.partnerList.partnerUpdate,
    method: "PUT",
    token: true,
    data: data,
  })
    .then((response) => {
      if (!response?.response?.status) {
        notify(`Partner updated!`, "success");
      } else {
        notify(response.message, "warn");
      }
      dispatch(getPartnerByIdAsync(data.id));
    })
    .catch((error) => {
      notify(error, "error");
    });
};
// Delete partner
export const deletePartnerAsync = (data, fetchParams) => (dispatch) => {
  dispatch(fetchLoadingStart());
  ApiService.apiCall({
    url: ApiService.paths.partnerList.partnerDelete,
    token: true,
    method: "DELETE",
    params: { id: data },
  })
    .then((response) => {
      dispatch(fetchLoadingFinish());

      if (!response?.response?.status) {
        notify(`Partner deleted!`, "success");
        fetchPartnerListGetAsync(dispatch, fetchParams);
      } else {
        notify(response.message, "warn");
      }
    })
    .catch((error) => {
      dispatch(
        fetchLoadingError({
          message: "Произошла ошибка во время удаления партнера",
        })
      );
      setTimeout(() => {
        dispatch(fetchLoadingClearError());
      }, 1000);
    });
};
// Get paginated Partner List list - {page,limit}
const fetchPartnerListGet = (data) => ({
  type: PartnerListActionTypes.FETCH_GET_PARTNER_LIST,
  payload: data,
});
export const fetchPartnerListGetAsync = (
  dispatch,
  params = { page: STARTING_PAGE - 1, limit: STARTING_PAGE_LIMIT }
) => {
  if (!params.limit === 1000) {
    dispatch(fetchLoadingStart());
  }

  ApiService.apiCall({
    url: ApiService.paths.partnerList.partnersGet,
    token: true,
    params: { ...params, page: params.page + 1 },
  })
    .then((response) => {
      const { partners, pages, total } = response?.data;
      if (params.limit === 1000) params.limit = STARTING_PAGE_LIMIT;
      dispatch(
        fetchPartnerListGet({
          list: partners,
          params: { limit: params.limit, pages, total },
        })
      );
      dispatch(fetchLoadingFinish());
    })
    .catch((error) => {
      dispatch(fetchLoadingError(error));
      setTimeout(() => {
        fetchLoadingClearError();
      }, 300);
    });
};
// Clear parthers anatalitics
export const fetchResetPartnerAnalitics = (dispatch) => {
  return ApiService.apiCall({
    url: ApiService.paths.partnerList.partnerResetAnalitics,
    token: true,
    method: "POST",
  }).then((response) => {
    notify(`Аналитика успешно очищена`, "success");
  });
};

// MODELZ actions
export const createModel = (data) => (dispatch) => {
  ApiService.apiCall({
    url: ApiService.paths.partnerList.modelCreate,
    token: true,
    method: "POST",
    data,
  })
    .then((response) => {
      dispatch(getPartnerByIdAsync(data.partnerId));
    })
    .catch((error) => {
      dispatch(
        fetchGetByIdPartnerError({
          message: "Произошла ошибка во время создания модели",
        })
      );
      setTimeout(() => {
        dispatch(fetchGetByIdPartnerClearError());
      }, 1000);
    });
};
export const updateModel = (data) => (dispatch) => {
  ApiService.apiCall({
    url: ApiService.paths.partnerList.modelEdit,
    token: true,
    method: "PUT",
    data,
  })
    .then((response) => {
      dispatch(getPartnerByIdAsync(data.partnerId));
    })
    .catch((error) => {
      dispatch(
        fetchGetByIdPartnerError({
          message: "Произошла ошибка во время обновления модели",
        })
      );
      setTimeout(() => {
        dispatch(fetchGetByIdPartnerClearError());
      }, 1000);
    });
};
export const deleteModel = (data) => (dispatch) => {
  ApiService.apiCall({
    url: ApiService.paths.partnerList.modelDelete,
    token: true,
    method: "DELETE",
    params: { id: data.id },
  })
    .then((response) => {
      dispatch(getPartnerByIdAsync(data.partnerId));
    })
    .catch((error) => {
      dispatch(
        fetchGetByIdPartnerError({
          message: "Произошла ошибка во время удаления модели",
        })
      );
      setTimeout(() => {
        dispatch(fetchGetByIdPartnerClearError());
      }, 1000);
    });
};
// Buttons actions
export const updateButton = (data) => (dispatch) => {
  ApiService.apiCall({
    url: ApiService.paths.partnerList.buttonsUpdate,
    token: true,
    method: "PUT",
    data: data.data,
  })
    .then((response) => {
      dispatch(getPartnerByIdAsync(data.partnerId));
    })
    .catch((error) => {
      dispatch(
        fetchGetByIdPartnerError({
          message: "Произошла ошибка во время обновления кнопки",
        })
      );
      setTimeout(() => {
        dispatch(fetchGetByIdPartnerClearError());
      }, 1000);
    });
};
// Webinars actions
export const createWebinar = (data) => (dispatch) => {
  ApiService.apiCall({
    url: ApiService.paths.partnerList.webinarCreate,
    token: true,
    method: "POST",
    data,
  })
    .then((response) => {
      dispatch(getPartnerByIdAsync(data.partnerId));
    })
    .catch((error) => {
      dispatch(
        fetchGetByIdPartnerError({
          message: "Произошла ошибка во время создания вебинара",
        })
      );
      setTimeout(() => {
        dispatch(fetchGetByIdPartnerClearError());
      }, 1000);
    });
};
export const updateWebinar = (data) => (dispatch) => {
  ApiService.apiCall({
    url: ApiService.paths.partnerList.webinarEdit,
    token: true,
    method: "PUT",
    data,
  })
    .then((response) => {
      dispatch(getPartnerByIdAsync(data.partnerId));
    })
    .catch((error) => {
      dispatch(
        fetchGetByIdPartnerError({
          message: "Произошла ошибка во время обновления вебинара",
        })
      );
      setTimeout(() => {
        dispatch(fetchGetByIdPartnerClearError());
      }, 1000);
    });
};
export const deleteWebinar = (data) => (dispatch) => {
  ApiService.apiCall({
    url: ApiService.paths.partnerList.webinarDelete,
    token: true,
    method: "DELETE",
    params: { id: data.id },
  })
    .then((response) => {
      dispatch(getPartnerByIdAsync(data.partnerId));
    })
    .catch((error) => {
      dispatch(
        fetchGetByIdPartnerError({
          message: "Произошла ошибка во время удаления вебинара",
        })
      );
      setTimeout(() => {
        dispatch(fetchGetByIdPartnerClearError());
      }, 1000);
    });
};

// Materials actions
export const createMaterials = (data) => (dispatch) => {
  ApiService.apiCall({
    url: ApiService.paths.partnerList.materialsCreate,
    token: true,
    method: "POST",
    data,
  })
    .then((response) => {
      dispatch(getPartnerByIdAsync(data.partnerId));
    })
    .catch((error) => {
      dispatch(
        fetchGetByIdPartnerError({
          message: "Произошла ошибка во время создания материала",
        })
      );
      setTimeout(() => {
        dispatch(fetchGetByIdPartnerClearError());
      }, 1000);
    });
};
export const updateMaterials = (data) => (dispatch) => {
  ApiService.apiCall({
    url: ApiService.paths.partnerList.materialsEdit,
    token: true,
    method: "PUT",
    data,
  })
    .then((response) => {
      dispatch(getPartnerByIdAsync(data.partnerId));
    })
    .catch((error) => {
      dispatch(
        fetchGetByIdPartnerError({
          message: "Произошла ошибка во время обновления материала",
        })
      );
      setTimeout(() => {
        dispatch(fetchGetByIdPartnerClearError());
      }, 1000);
    });
};
export const deleteMaterials = (data) => (dispatch) => {
  ApiService.apiCall({
    url: ApiService.paths.partnerList.materialsDelete,
    token: true,
    method: "DELETE",
    params: { id: data.id },
  })
    .then((response) => {
      dispatch(getPartnerByIdAsync(data.partnerId));
    })
    .catch((error) => {
      dispatch(
        fetchGetByIdPartnerError({
          message: "Произошла ошибка во время удаления материала",
        })
      );
      setTimeout(() => {
        dispatch(fetchGetByIdPartnerClearError());
      }, 1000);
    });
};
