export const hallTypes = [
  {
    value: 1,
    text: "Великий Зал День 1",
  },
  { value: 2, text: "Малий Зал День 1" },
  { value: 3, text: "Музейний Зал День 1" },
  {
    value: 4,
    text: "Великий Зал День 2",
  },
  { value: 5, text: "Малий Зал День 2" },
  { value: 6, text: "Музейний Зал День 2" },
];

export const dateTypes = [
  {
    value: 1,
    text: '20 мая',
  },
  {
    value: 2,
    text: '21 мая',
  }
]

export const languageTypes = [
  { value: "UA", text: "Українська" },
  { value: "EN", text: "English" },
];

export const modelTypesLimit = {
  VERTICAL_BAR: 2,
  HORIZONTAL_BAR: 1,
  TV: 3,
  RECEPTION: 1,
};
export const modelTypesList = [
  {
    text: "Вертикальный",
    value: "VERTICAL_BAR",
  },  {
    text: "Горизонтальный",
    value: "HORIZONTAL_BAR",
  },  {
    text: "Телевизоры",
    value: "TV",
  },  {
    text: "Кафедра",
    value: "RECEPTION",
  },
];
export const sessionTypeOptions = [
  {
    text: "Заседание",
    value: "SESSION",
  },  {
    text: "Симпозиум",
    value: "SYMPOSIUM",
  }, 
]