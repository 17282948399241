import React, { lazy, Suspense, useEffect } from "react";
import { Switch, Route, Redirect, useHistory } from "react-router-dom";
import Backdrop from "@material-ui/core/Backdrop";
// Navigation
import routes from "./common/routes";
// Redux
import { useSelector, useDispatch } from "react-redux";
import actions from "./redux/actions";
// Services
import StorageService from "./services/StorageService";
import notify from "./services/Notifications";
// Styles
import "react-toastify/dist/ReactToastify.min.css";

const { fetchCurrentUserAsync } = actions;

const ViewMain = React.lazy(() =>
  import(/* webpackChunkName: "views" */ "./common/pages")
);
const ViewApp = lazy(() =>
  import(/* webpackChunkName: "app" */ "./common/pages/app")
);
const ViewAuth = lazy(() =>
  import(/* webpackChunkName: "auth" */ "./common/layout/Authentication")
);
const ViewError = lazy(() =>
  import(/* webpackChunkName: "error" */ "./common/pages/error")
);

const AuthRoute = ({ component: Component, ...rest }) => {
  const isAuthorized = useSelector((state) => state.user.isAuthorized);

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthorized ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/user/login",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

const App = () => {
  const isAuth = useSelector(({ user: { isAuthorized } }) => isAuthorized);
  const dispatch = useDispatch();
  const history = useHistory();
  // START DEVELOPMENT STAGE ERRORS
  const error = useSelector((state) => state.loading?.error);
  useEffect(() => {
    if (error) notify(error, "error");
    if (error?.message) notify(error?.message, "error");
  }, [error]);
  // END development stage errors (modify on prod)
  useEffect(() => {
    if (StorageService.getToken()) {
      fetchCurrentUserAsync(dispatch, history);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Suspense fallback={<Backdrop open />}>
      <Switch>
        <AuthRoute path={"/app"} isAuthorized={isAuth} component={ViewApp} />
        <Route
          path={routes.auth}
          exact
          render={(props) => {
            return <ViewAuth {...props} />;
          }}
        />
        <Route
          path={routes.error}
          exact
          render={(props) => <ViewError {...props} />}
        />
        <Route path="/" exact render={(props) => <ViewMain {...props} />} />

        <Redirect to={routes.error} />
      </Switch>
    </Suspense>
  );
};

export default App;
