// Types
import { SessionsListActionTypes } from "../types";
// Services
import ApiService from "../../services/ApiService";
import notify from "../../services/Notifications";
// Constants
import {
  STARTING_PAGE,
  STARTING_PAGE_LIMIT,
} from "../../common/constants/tableConfig";

const fetchSessionError=(data)=>({
  type:SessionsListActionTypes.FETCH_GET_SESSIONS_LIST_ERROR,
  payload:data
})
const fetchSessionClearError=()=>({
  type:SessionsListActionTypes.FETCH_GET_SESSIONS_LIST_CLEAR_ERROR
})
const fetchSessionGetSuccess = (data) => ({
  type: SessionsListActionTypes.FETCH_GET_SESSIONS_LIST_SUCCESS,
  payload: data,
});

const fetchSessionGetStart = () => ({
  type: SessionsListActionTypes.FETCH_GET_SESSIONS_LIST_START,
});
// Create program
export const createSessionAsync = (data, fetchParams) => (dispatch) => {
  ApiService.apiCall({
    url: ApiService.paths.sessionList.sessionsCreate,
    token: true,
    method: "POST",
    data: data,
  })
    .then((response) => {

      if (!response?.response?.status) {
        notify(`Session created!`);
        fetchSessionListByHallIdAsync(dispatch, fetchParams);
      } else {
        notify(response.message, "warn");
      }
    })
    .catch((error) => {
      dispatch(
        fetchSessionError({
          message: "Произошла ошибка во время создания заседания",
        })
      );
      setTimeout(() => {
        dispatch(fetchSessionClearError());
      }, 1000);
    });
};
// Edit program
export const updateSessionAsync = (data, fetchParams) => (dispatch) => {
  ApiService.apiCall({
    url: ApiService.paths.sessionList.sessionsUpdate,
    method: "PUT",
    token: true,
    data: data,
  })
    .then((response) => {
      if (!response?.response?.status) {
        notify(`Program updated!`, "success");
        fetchSessionListByHallIdAsync(dispatch, fetchParams);
      } else {
        notify(response.message, "warn");
      }
    })
    .catch((error) => {
      dispatch(
        fetchSessionError({
          message: "Произошла ошибка во время редактирования заседания",
        })
      );
      setTimeout(() => {
        dispatch(fetchSessionClearError());
      }, 1000);
    });
};
// Delete programs
export const deleteSessionAsync = (data, fetchParams) => (dispatch) => {
  ApiService.apiCall({
    url: ApiService.paths.sessionList.sessionsDelete,
    token: true,
    method: "DELETE",
    params: { id: data },
  })
    .then((response) => {
      if (!response?.response?.status || response?.data?.total === 0) {
        notify(`Program deleted!`, "success");
        fetchSessionListByHallIdAsync(dispatch, fetchParams);
      } else {
        notify(response.message, "warn");
      }
    })
    .catch((error) => {
      dispatch(
        fetchSessionError({
          message: "Произошла ошибка во время удаления заседания",
        })
      );
      setTimeout(() => {
        dispatch(fetchSessionClearError());
      }, 1000);
    });
};


export const fetchSessionListByHallIdAsync = (
  dispatch,
  params = { page: STARTING_PAGE - 1, limit: STARTING_PAGE_LIMIT,loading:true }
) => {
  
  if(params.loading) dispatch(fetchSessionGetStart());
  ApiService.apiCall({
    url: ApiService.paths.sessionList.sessionssGetByCast,
    token: true,
    params: { page: params.page + 1, limit: params.limit, broadcastId:params.id },
  })
    .then((response) => {
      if (response.data.total === 0) {
        dispatch(
          fetchSessionGetSuccess({
            list: [],
            params: { page: 0, total: 0, limit: 5 },
          })
        );
      } else {
        const { sessions, pages, total } = response?.data;
        dispatch(
          fetchSessionGetSuccess({
            list: sessions,
            params: { limit: params.limit, pages, total },
          })
        );
      }
    })
    .catch((error) => {
      dispatch(fetchSessionError(error));
      setTimeout(() => {
        fetchSessionClearError();
      }, 300);
    });
};
