import { EposterListActionTypes } from "../types";
// Constants
import {
  STARTING_PAGE,
  STARTING_PAGE_LIMIT,
} from "../../common/constants/tableConfig";

const { FETCH_GET_EPOSTER_LIST } = EposterListActionTypes;

const INIT_STATE = {
  list: [],
  params: {
    page: STARTING_PAGE,
    limit: STARTING_PAGE_LIMIT,
    total: 0,
  },
  item: null,
};
const eposterListReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_GET_EPOSTER_LIST:
      return {
        ...state,
        params: action.payload.params,
        list: action.payload.list || [],
        total: action.payload.total,
      };

    default:
      return state;
  }
};

export default eposterListReducer;
