// Loading actions
import {
  fetchLoadingFinish,
  fetchLoadingStart,
  fetchLoadingError,
  fetchLoadingClearError,
} from "./loadingAction";
// Types
import { UserListActionTypes } from "../types";
// Services
import ApiService from "../../services/ApiService";
import notify from "../../services/Notifications";
// Constants
import {STARTING_PAGE_LIMIT} from '../../common/constants/tableConfig'

// Create user
export const createUserAsync = (data,fetchParams) => (dispatch) => {
  dispatch(fetchLoadingStart());
  ApiService.apiCall({
    url: ApiService.paths.userList.userCreate,
    method: "POST",
    token: true,
    data: data,
  })
    .then((response) => {
      dispatch(fetchLoadingFinish());

      if (!response?.response?.status) {
        notify(`${data.firstName} ${data.lastName} user created!`,'success');
        fetchUserListGetAsync(dispatch,fetchParams);
      } else if(response?.response?.data?.message==="DUPLICATED_USER_ADMIN_ERROR"){
        notify("Пользователь с таким почтовым адересом уже существует", "warn");
      }else {
        notify(response.message, "warn");
      }
    })
    .catch((error) => {
      dispatch(
        fetchLoadingError({
          message: "Произошла ошибка во время создания пользователя",
        })
      );
      setTimeout(() => {
        dispatch(fetchLoadingClearError());
      }, 1000);
    });
};
// Edit user
export const updateUserAsync = (data,fetchParams) => (dispatch) => {
  dispatch(fetchLoadingStart());
  ApiService.apiCall({
    url: ApiService.paths.userList.userUpdate,
    method: "PUT",
    token: true,
    data: data,
  })
    .then((response) => {
      dispatch(fetchLoadingFinish());

      if (!response?.response?.status) {
        notify(`User updated!`,'success');
        fetchUserListGetAsync(dispatch,fetchParams);
      } else {
        notify(response.message, "warn");
      }
    })
    .catch((error) => {
      dispatch(
        fetchLoadingError({
          message: "Произошла ошибка во время редактирования пользователя",
        })
      );
      setTimeout(() => {
        dispatch(fetchLoadingClearError());
      }, 1000);
    });
};
// Delete user
export const deleteUserAsync = (data,fetchParams) => (dispatch) => {
  dispatch(fetchLoadingStart());
  ApiService.apiCall({
    url: ApiService.paths.userList.userDelete,
    method: "DELETE",
    token: true,
    params: { id: data },
  })
    .then((response) => {
      dispatch(fetchLoadingFinish());

      if (!response?.response?.status) {
        notify(`User deleted!`,'success');
        fetchUserListGetAsync(dispatch,fetchParams);
      } else {
        notify(response.message, "warn");
      }
    })
    .catch((error) => {
      dispatch(
        fetchLoadingError({
          message: "Произошла ошибка во время удаления пользователя",
        })
      );
      setTimeout(() => {
        dispatch(fetchLoadingClearError());
      }, 1000);
    });
};
// Get paginated user list - {page,limit}
const fetchUserListGet = (data) => ({
  type: UserListActionTypes.FETCH_GET_USER_LIST,
  payload: data,
});
export const fetchUserListGetAsync = (
  dispatch,
  params = { page: 0, limit: STARTING_PAGE_LIMIT,onlyUnpaid:null, sortBy:'id', sortDirection: 'ASC',}
) => {
  dispatch(fetchLoadingStart());

  ApiService.apiCall({
    url: ApiService.paths.userList.usersGet,
    token: true,
    params:{...params,page:params.page+1},
  })
    .then((response) => {
      const { users, pages, total, exportFile} = response.data;
      dispatch(
        fetchUserListGet({
          list: users,
          params: { limit: params?.limit, pages, total },
          listLink: exportFile
        })
      );
      dispatch(fetchLoadingFinish());
    })
    .catch((error) => {
      dispatch(fetchLoadingError(error));
      setTimeout(() => {
        fetchLoadingClearError();
      }, 300);
    });
};

const fetchUserListExceltGet = (data) => ({
  type: UserListActionTypes.FETCH_GET_USER_EXCEL_LIST,
  userListLink: data,
});
export const fetchExcelUserListGetAsync = () => dispatch => {
  dispatch(fetchLoadingStart());

  ApiService.apiCall({
    url: ApiService.paths.userList.userGetExcel,
    token: true,
  })
    .then((response) => {
      dispatch(fetchUserListExceltGet(response.url))
      dispatch(fetchLoadingFinish());
    })
    .catch((error) => {
      dispatch(fetchLoadingError(error));
      setTimeout(() => {
        fetchLoadingClearError();
      }, 300);
    });
};
