// Loading actions
import {
  fetchLoadingFinish,
  fetchLoadingStart,
  fetchLoadingError,
  fetchLoadingClearError,
} from "./loadingAction";
// Types
import { QaListActionTypes } from "../types";
// Services
import ApiService from "../../services/ApiService";
import notify from "../../services/Notifications";
// Constants
import {
  STARTING_PAGE,
  STARTING_PAGE_LIMIT,
} from "../../common/constants/tableConfig";

// Create qa
export const createQaAsync = (data, fetchParams) => (dispatch) => {
  dispatch(fetchLoadingStart());
  ApiService.apiCall({
    url: ApiService.paths.qaList.qaCreate,
    token: true,
    method: "POST",
    data: data,
  })
    .then((response) => {
      dispatch(fetchLoadingFinish());

      if (!response?.response?.status) {
        notify(`QA created!`, "success");
        fetchQaListGetAsync(dispatch, fetchParams);
      } else {
        notify(response.message, "warn");
      }
    })
    .catch((error) => {
      dispatch(
        fetchLoadingError({
          message: "Произошла ошибка во время создания вопроса",
        })
      );
      setTimeout(() => {
        dispatch(fetchLoadingClearError());
      }, 1000);
    });
};
// Edit qa
export const updateQaAsync = (data, fetchParams) => (dispatch) => {
  dispatch(fetchLoadingStart());
  ApiService.apiCall({
    url: ApiService.paths.qaList.qaUpdate,
    method: "PUT",
    token: true,
    data: data,
  })
    .then((response) => {
      dispatch(fetchLoadingFinish());

      if (!response?.response?.status) {
        notify(`QA updated!`, "success");
        fetchQaListGetAsync(dispatch, fetchParams);
      } else {
        notify(response.message, "warn");
      }
    })
    .catch((error) => {
      dispatch(
        fetchLoadingError({
          message: "Произошла ошибка во время редактирования вопроса",
        })
      );
      setTimeout(() => {
        dispatch(fetchLoadingClearError());
      }, 1000);
    });
};
// Delete qa
export const deleteQaAsync = (data, fetchParams) => (dispatch) => {
  dispatch(fetchLoadingStart());
  ApiService.apiCall({
    url: ApiService.paths.qaList.qaDelete,
    token: true,
    method: "DELETE",
    params: { id: data },
  })
    .then((response) => {
      dispatch(fetchLoadingFinish());

      if (!response?.response?.status) {
        notify(`QA deleted!`, "success");
        fetchQaListGetAsync(dispatch, fetchParams);
      } else {
        notify(response.message, "warn");
      }
    })
    .catch((error) => {
      dispatch(
        fetchLoadingError({
          message: "Произошла ошибка во время удаления вопроса",
        })
      );
      setTimeout(() => {
        dispatch(fetchLoadingClearError());
      }, 1000);
    });
};
// Get paginated QA list - {page,limit}
const fetchQaListGet = (data) => ({
  type: QaListActionTypes.FETCH_GET_QA_LIST,
  payload: data,
});
export const fetchQaListGetAsync = (
  dispatch,
  params = { page: STARTING_PAGE - 1, limit: STARTING_PAGE_LIMIT }
) => {
  dispatch(fetchLoadingStart());

  ApiService.apiCall({
    url: ApiService.paths.qaList.qasGet,
    token: true,
    params: { ...params, page: params.page + 1 },
  })
    .then((response) => {
      const { questions, pages, total } = response?.data;
      dispatch(
        fetchQaListGet({
          list: questions,
          params: { limit: params.limit, pages, total },
        })
      );
      dispatch(fetchLoadingFinish());
    })
    .catch((error) => {
      dispatch(fetchLoadingError(error));
      setTimeout(() => {
        fetchLoadingClearError();
      }, 300);
    });
};
