import { UserActionTypes } from "../types";
import StorageService from "../../services/StorageService";
// services
const INIT_STATE = {
  user: StorageService.getSession() || null,
  isAuthorized: !!StorageService.getToken(),
  loading: false,
  error: "",
};

const userReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case UserActionTypes.CLEAR_USER_ERROR:
      return { ...state, error: "" };

    case UserActionTypes.FETCH_LOGIN_USER_START:
      return { ...state, loading: true, error: "" };

    case UserActionTypes.FETCH_LOGIN_USER_SUCCESS:
      return { ...state, isAuthorized: true, loading: false, error: "" };

    case UserActionTypes.FETCH_LOGIN_USER_ERROR:
      return {
        ...state,
        isAuthorized: false,
        loading: false,
        error: action.payload.message,
      };

    case UserActionTypes.FETCH_CURRENT_USER_START:
      return { ...state, isAuthorized: true, loading: true, error: "" };

    case UserActionTypes.FETCH_CURRENT_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        isAuthorized: true,
        user: action.payload,
        error: "",
      };

    case UserActionTypes.FETCH_CURRENT_USER_ERROR:
      return {
        ...state,
        loading: false,
        isAuthorized: false,
        error: action.payload,
      };

    case UserActionTypes.LOGOUT_USER:
      return {
        ...state,
        user: null,
        isAuthorized: false,
        error: "",
        loading: false,
      };

    default:
      return state;
  }
};

export default userReducer;
