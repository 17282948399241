// Loading actions
import {
    fetchLoadingFinish,
    fetchLoadingStart,
    fetchLoadingError,
    fetchLoadingClearError,
  } from "./loadingAction";
  // Types
  import { BroadcastListActionTypes } from "../types";
  // Services
  import ApiService from "../../services/ApiService";
  import notify from "../../services/Notifications";
  // Constants
  import {STARTING_PAGE} from '../../common/constants/tableConfig'
  // Create qa
  export const createBroadcastAsync = (data) => (dispatch) => {
    dispatch(fetchLoadingStart());
    ApiService.apiCall({
      url: ApiService.paths.broadCastList.broadcastsCreate,
      token: true,
      method: "POST",
      data: data,
    })
      .then((response) => {
        dispatch(fetchLoadingFinish());
  
        if (!response?.response?.status) {
          notify(`Broadcast created!`);
          fetchBroadcastListAsync(dispatch);
        } else {
          notify(response.message, "warn");
        }
      })
      .catch((error) => {
        dispatch(
          fetchLoadingError({
            message: "Произошла ошибка во время создания трансляции",
          })
        );
        setTimeout(() => {
          dispatch(fetchLoadingClearError());
        }, 1000);
      });
  };
  // Edit qa
  export const updateBroadcastAsync = (data) => (dispatch) => {
    dispatch(fetchLoadingStart());
    ApiService.apiCall({
      url: ApiService.paths.broadCastList.broadcastsUpdate,
      method: "PUT",
      token: true,
      data: data,
    })
      .then((response) => {
        dispatch(fetchLoadingFinish());
  
        if (!response?.response?.status) {
          notify(`Broadcast updated!`,'success');
          fetchBroadcastListAsync(dispatch);
        } else {
          notify(response.message, "warn");
        }
      })
      .catch((error) => {
        dispatch(
          fetchLoadingError({
            message: "Произошла ошибка во время редактирования вопроса",
          })
        );
        setTimeout(() => {
          dispatch(fetchLoadingClearError());
        }, 1000);
      });
  };
  // Delete qa
  export const deleteBroadcastAsync = (data) => (dispatch) => {
    dispatch(fetchLoadingStart());
    ApiService.apiCall({
      url: ApiService.paths.broadCastList.broadcastsDelete,
      token: true,
      method: "DELETE",
      params: { broadcastId: data },
    })
      .then((response) => {
        dispatch(fetchLoadingFinish());
  
        if (!response?.response?.status) {
          notify(`Broadcast deleted!`,'success');
          fetchBroadcastListAsync(dispatch);
        } else {
          notify(response.message, "warn");
        }
      })
      .catch((error) => {
        dispatch(
          fetchLoadingError({
            message: "Произошла ошибка во время удаления трансляции",
          })
        );
        setTimeout(() => {
          dispatch(fetchLoadingClearError());
        }, 1000);
      });
  };
  // Get paginated QA list - {page,limit}
  const fetchBroadcastGet = (data) => ({
    type: BroadcastListActionTypes.FETCH_GET_BROADCAST_LIST,
    payload: data,
  });
  export const fetchBroadcastListAsync = (
    dispatch,
    params = { page: STARTING_PAGE-1, limit: 10}
  ) => {
    dispatch(fetchLoadingStart());
  
    ApiService.apiCall({
      url: ApiService.paths.broadCastList.broadcastssGet,
      token: true,
      params:{...params,page:params.page+1},
    })
      .then((response) => {
        const { broadcasts, pages, total } = response?.data;
        dispatch(
          fetchBroadcastGet({
            list: broadcasts,
            params: { limit: params.limit,pages, total },
          })
        );
        dispatch(fetchLoadingFinish());
      })
      .catch((error) => {
        dispatch(fetchLoadingError(error));
        setTimeout(() => {
          fetchLoadingClearError();
        }, 300);
      });
  };
  