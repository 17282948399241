import { ContentListTypes } from "../types";

const {
  FETCH_GET_CONTENT_LIST_START,
  FETCH_GET_CONTENT_LIST_SUCCESS,
  FETCH_GET_CONTENT_LIST_ERROR,
  FETCH_GET_CONTENT_LIST_CLEAR_ERROR
} = ContentListTypes;

const INIT_STATE = {
  fields: [],
  loading: false,
  error: "",
};
const contentsListReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_GET_CONTENT_LIST_START:
      return { ...state, loading: true, error: "", fields: []};
    case FETCH_GET_CONTENT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        fields: action.fields,
      };
    case FETCH_GET_CONTENT_LIST_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case FETCH_GET_CONTENT_LIST_CLEAR_ERROR:
      return { ...state, error: "" };
    default:
      return state;
  }
};

export default contentsListReducer;
