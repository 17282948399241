export const BASE_API = "/api/v1";
export const API_URL =
  process.env.REACT_APP_API_URL ||
  "http://filatov-readings.demo.malevich.com.ua/api/v1"; // 'http://localhost:3000'

const routes = {
  auth: "/user/login",
  main: "/main",
  userList: "/userlist",
  questions: "/qalist",
  eposters: "/eposters",
  partnersList: "/partnerslist",
  programList: "/programlist",
  broadcasts: "/broadcastList",
  chats: "/chats",
  control: "/control",
  datesList: "/datesList",
  hallsList: "/hallsList",
  materialsList: "/materialsList",
  sertificates: "/sertificates",
  error: "/error",
  app: {
    main: "/app/main",
    userList: "/app/userlist",
    questions: "/app/qalist",
    eposters: "/app/eposters",
    partnersList: "/app/partnerslist",
    programList: "/app/programlist",
    broadcasts: "/app/broadcastlist",
    chats: "/app/chats",
    control: "/app/control",
    datesList: "/app/datesList",
    hallsList: "/app/hallsList",
    materialsList: "/app/materialsList",
    sertificates: "/app/sertificates",
  },
  userListPage: {
    list: "/list",
  },
  qaListPage: {
    list: "/list",
  },
  broadcastListPage: {
    list: "/list",
  },
  programListPage: {
    list: "/list",
  },

  partnerListPage: {
    list: "/list",
    new: "/app/partnerslist/new",
    edit: "/app/partnerslist/edit/",
  },
  chatsPage: {
    list: "/chats",
  },
  api: {
    upload: "/admin/files/upload",
    fileDelete: "/admin/files/delete",
    filesGet: "/admin/files/get",
    user: {
      LOGIN: "/admin/auth/signIn", // login: andrii.dolhaniuk@gmail.com || password: hashed_password
      CURRENT_USER: "/users/fetch",
    },
    userList: {
      userCreate: "/admin/users/create",
      userUpdate: "/admin/users/edit",
      userDelete: "/admin/users/delete",
      usersGet: "/admin/users/get",
      userGetExcel: "/admin/users/export",
    },
    qaList: {
      qaCreate: "/admin/questions/create",
      qaUpdate: "/admin/questions/edit",
      qaDelete: "/admin/questions/delete",
      qasGet: "/admin/questions/get",
    },
    broadCastList: {
      broadcastsCreate: "/admin/broadcasts/create",
      broadcastsUpdate: "/admin/broadcasts/edit",
      broadcastsDelete: "/admin/broadcasts/delete",
      broadcastssGet: "/admin/broadcasts/get",
    },
    programList: {
      programsCreate: "/admin/programs/create",
      programsUpdate: "/admin/programs/edit",
      programsDelete: "/admin/programs/delete",
      programssGet: "/admin/programs/get",
      programsGetByHall: "/programs/getProgramsByHall",
      programsGetBySessionId: "/admin/programs/getProgramsBySession",
    },
    sessionList: {
      sessionsCreate: "/admin/sessions/create",
      sessionsUpdate: "/admin/sessions/edit",
      sessionsDelete: "/admin/sessions/delete",
      sessionssGet: "/admin/sessions/get",
      sessionssGetByCast: "/admin/sessions/getSessionsByHall",
    },
    chats: {
      chatsGet: "/admin/chats/get",
      messageDelete: "/admin/chats/delete",
    },
    eposters: {
      epostersCreate: "/admin/eposters/create",
      epostersEdit: "/admin/eposters/edit",
      epostersDelete: "/admin/eposters/delete",
      epostersGet: "/admin/eposters/get",
    },
    partnerList: {
      partnersGet: "/admin/partners/get",
      partnerCreate: "/admin/partners/create",
      partnerUpdate: "/admin/partners/edit",
      partnerDelete: "/admin/partners/delete",
      partnerGetById: "/admin/partners/getPartnerById",
      partnerResetAnalitics: "/admin/partners/resetCounters",
      // BUTTONS
      buttonsUpdate: "/admin/buttons/edit",
      // MODELZ
      modelCreate: "/admin/models/create",
      modelEdit: "/admin/models/edit",
      modelDelete: "/admin/models/delete",
      // WeBINARS
      webinarCreate: "/admin/webinars/create",
      webinarEdit: "/admin/webinars/edit",
      webinarDelete: "/admin/webinars/delete",
      // MATERIALS
      materialsCreate: "/admin/materials/create",
      materialsEdit: "/admin/materials/edit",
      materialsDelete: "/admin/materials/delete",
    },
    dates: {
      dateCreate: "/admin/date/create",
      dateDelete: "/admin/date/delete",
      dateEdit: "/admin/date/edit",
      dateGet: "/admin/date/get",
    },
    halls: {
      hallCreate: "/admin/hall/create",
      hallDelete: "/admin/hall/delete",
      hallEdit: "/admin/hall/edit",
      hallGet: "/admin/hall/get",
    },
    contents: {
      contentEdit: "/admin/content/edit",
      contentGet: "/admin/content/get",
    },
    materials: {
      materialEdit: "/admin/pdfmaterials/edit",
      materialGet: "/admin/pdfmaterials/get",
    },
    sertificates: {
      sertificateEdit: "/admin/certificate/replace",
    },
  },
};

export default routes;
