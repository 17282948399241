import { FileTypes } from "../types";

const {
  FETCH_GET_FILES_SUCCESS,
} = FileTypes;

const INIT_STATE = {
  list: [],
  fetching: false,
  error: "",
};
const fileListReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_GET_FILES_SUCCESS:
      return {
        ...state,
        list: action.payload,
        fetching: false,
        error: "",
      };

  
    default:
      return state;
  }
};

export default fileListReducer;
