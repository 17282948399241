import { LoadingTypes } from "../types";
// services
const INIT_STATE = {
  loading: false,
  error: "",
};

const loadingReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LoadingTypes.LOADING_START:
      return { ...state, loading: true };

    case LoadingTypes.LOADING_FINISH:
      return { ...state, loading: false };

    case LoadingTypes.LOADING_ERROR:
      return { ...state, loading: false, error: action.payload };

    case LoadingTypes.LOADING_CLEAR_ERROR:
      return { ...state, error: "" }; 
      
    default:
      return state;
  }
};

export default loadingReducer;
