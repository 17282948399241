import { ChatsActionTypes } from "../types";


const {
  FETCH_CLEAR_ERROR,
  FETCH_DELETE_MESSAGE_ERROR,
  FETCH_DELETE_MESSAGE_START,
  FETCH_DELETE_MESSAGE_SUCCESS,
  FETCH_GET_MESSAGE_LIST_START,
  FETCH_GET_MESSAGE_LIST_SUCCESS,
  FETCH_MESSAGE_ERROR,
} = ChatsActionTypes;

const INIT_STATE = {
  list: [],
  params: {
    id: 0,
  },
  fetching: false,
  error: "",
  item: null,
};
const programListReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_DELETE_MESSAGE_START:
    case FETCH_GET_MESSAGE_LIST_START:
      return { ...state, fetching: true, error: "" };
    case FETCH_DELETE_MESSAGE_SUCCESS:
      return { ...state, fetching: false };
    case FETCH_DELETE_MESSAGE_ERROR:
    case FETCH_MESSAGE_ERROR:
      return {
        ...state,
        fetching: false,
        error: action.payload,
        list: [],
        params: INIT_STATE.params,
      };
    case FETCH_GET_MESSAGE_LIST_SUCCESS:
      return {
        ...state,
        params: action.payload.params,
        list: action.payload.list,
        fetching: false,
        error: "",
      };

    case FETCH_CLEAR_ERROR:
      return { ...state, error: "" };

    default:
      return state;
  }
};

export default programListReducer;
