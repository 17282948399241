
const INIT_STATE = {
};

const settingsReducer= (state = INIT_STATE, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export default settingsReducer;