// Utils
import axios from "axios";
import history from "../history";
import notify from "../services/Notifications";

// Services
import StorageService from "../services/StorageService";

// Store
import store from "../redux/store";

// Constants
import { UserActionTypes } from "../redux/types";
import routes from "../common/routes";
import { API_URL } from "../common/constants/apiTypes";

class ApiService {
  constructor() {
    this.client = axios.create({
      baseURL: API_URL,
      timeout: 60 * 1000, // 1 min
    });
    this.paths = routes.api;
  }

  apiCall({
    url = "",
    method = "GET",
    token = false,
    onUploadProgress = () => {},
    cancelToken = false,
    ...otherParams
  }) {
    let options = {
      url,
      method,
      headers: this.buildHeaders(token, otherParams),
      onUploadProgress,
      cancelToken,
    };

    if (otherParams) options = { ...options, ...otherParams };

    return this.client(options)
      .then(this.handleCommonSuccess)
      .catch(this.handleCommonError);
  }

  buildHeaders(token, otherParams = {}) {
    let headers = {
      "Content-type": otherParams.multi
        ? "multipart/form-data"
        : "application/json",
      Accept: "application/json",
    };

    if (token) headers["Authorization"] = `Bearer ${StorageService.getToken()}`;

    return headers;
  }

  handleCommonSuccess(response) {
    return response.data;
  }

  handleCommonError(error) {
    if (axios.isCancel(error)) {
      notify(error.message, "error");
      console.log("Request canceled", error.message);
    }

    if (error.response?.status === 401) {
      store.dispatch({ type: UserActionTypes.LOGOUT_USER });
      history.push("/");
    }
    if (error.response?.status === 403) {
      store.dispatch({ type: UserActionTypes.LOGOUT_USER });
      history.push("/");
    }
    if (error.response?.status === 500) {
      history.push("/");
    }

    return error;
  }
}

export default new ApiService();
