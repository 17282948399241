import React, { Suspense } from "react";
import {
  BrowserRouter as Router,
} from "react-router-dom";
import App from './App'
import CssBaseline from "@material-ui/core/CssBaseline";
import Backdrop from '@material-ui/core/Backdrop';
// Redux
import { Provider } from "react-redux";
import store from "./redux/store";
import history from './history'
// Date-time
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,

} from '@material-ui/pickers';

const AppContainer = () => {
  return (
    <Provider store={store}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <CssBaseline />
      <Router history={history}>
      <Suspense fallback={<Backdrop />}>
       <App />
       </Suspense>
      </Router>
      </MuiPickersUtilsProvider>
    </Provider>
  );
};


export default AppContainer

