import { HallListTypes } from "../types";

const {
  FETCH_GET_HALL_LIST_START,
  FETCH_GET_HALL_LIST_SUCCESS,
  FETCH_GET_HALL_LIST_ERROR,
  FETCH_GET_HALL_LIST_CLEAR_ERROR
} = HallListTypes;

const INIT_STATE = {
  halls: [],
  loading: false,
  error: "",
};
const hallsListReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_GET_HALL_LIST_START:
      return { ...state, loading: true, error: ""};
    case FETCH_GET_HALL_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        halls: action.halls,
      };
    case FETCH_GET_HALL_LIST_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case FETCH_GET_HALL_LIST_CLEAR_ERROR:
      return { ...state, error: "" };
    default:
      return state;
  }
};

export default hallsListReducer;
