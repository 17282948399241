// Types
import { MaterialsListTypes } from "../types";
// Services
import ApiService from "../../services/ApiService";
import notify from "../../services/Notifications";

const fetchMaterialsError=(data)=>({
  type:MaterialsListTypes.FETCH_GET_MATERIAL_LIST_ERROR,
  payload:data
})
const fetchMaterialsClearError=()=>({
  type:MaterialsListTypes.FETCH_GET_MATERIAL_LIST_CLEAR_ERROR
})
const fetchMaterialsGetSuccess = (data) => ({
  type: MaterialsListTypes.FETCH_GET_MATERIAL_LIST_SUCCESS,
  materials: data,
});

const fetchMaterialsGetStart = () => ({
  type: MaterialsListTypes.FETCH_GET_MATERIAL_LIST_START,
});

export const fetchMaterialsListAsync = (
  dispatch,
) => {

  dispatch(fetchMaterialsGetStart());

  ApiService.apiCall({
    url: ApiService.paths.materials.materialGet,
    token: true,
  })
    .then((response) => {
      if(response?.materials) {
        dispatch(fetchMaterialsGetSuccess(response?.materials?.materials))
      }
    })
    .catch((error) => {
      dispatch(fetchMaterialsError(error));
      setTimeout(() => {
        fetchMaterialsClearError();
      }, 300);
    });
};

export const updateMaterialsAsync = (data) => (dispatch) => {
  ApiService.apiCall({
    url: ApiService.paths.materials.materialEdit,
    token: true,
    method: "PUT",
    data: data,
  })
    .then(response => {
      if (response?.data?.status) {
          notify(`Данные успешно отредактированы!`, "success");
          fetchMaterialsListAsync(dispatch);
        } else {
          notify(response.message, "warn");
        }
    })
    .catch((error) => {
        dispatch(
          fetchMaterialsError({
            message: "Произошла ошибка во время редактирования материалов",
          })
        );
        setTimeout(() => {
          dispatch(fetchMaterialsClearError());
        }, 1000);
      });
  };
