import { PartnerListActionTypes } from "../types";
// Constants
import {
  STARTING_PAGE,
  STARTING_PAGE_LIMIT,
} from "../../common/constants/tableConfig";

const {
  FETCH_GET_PARTNER_LIST,
  FETCH_GET_PARTNER_BY_ID_CLEAR_ERROR,
  FETCH_GET_PARTNER_BY_ID_ERROR,
  FETCH_GET_PARTNER_BY_ID_START,
  FETCH_GET_PARTNER_BY_ID_SUCCESS,
  CLEAR_CURRENT_PARTNER_DATA,
} = PartnerListActionTypes;

const INIT_STATE = {
  list: [],
  currentPartner: {},
  params: {
    page: STARTING_PAGE,
    limit: STARTING_PAGE_LIMIT,
    total: 0,
  },
  loading: "",
  error: "",
};
const qaListReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_GET_PARTNER_LIST:
      return {
        ...state,
        params: action.payload.params,
        list: action.payload.list || [],
        total: action.payload.total===1000?STARTING_PAGE_LIMIT:action.payload.total,
      };
    case CLEAR_CURRENT_PARTNER_DATA:
      return { ...state, currentPartner: action.payload };
    case FETCH_GET_PARTNER_BY_ID_START:
      return { ...state, loading: true };
    case FETCH_GET_PARTNER_BY_ID_SUCCESS:
      return { ...state, loading: false, currentPartner: action.payload };
    case FETCH_GET_PARTNER_BY_ID_ERROR:
      return { ...state, loading: false, error: action.payload };
    case FETCH_GET_PARTNER_BY_ID_CLEAR_ERROR:
      return { ...state, error: "" };
    default:
      return state;
  }
};

export default qaListReducer;
