// Loading actions
import {
  fetchLoadingError,
  fetchLoadingClearError,
} from "./loadingAction";
// Types
import { ProgramListActionTypes } from "../types";
// Services
import ApiService from "../../services/ApiService";
import notify from "../../services/Notifications";
import GetData from "../../services/GetDataJson";
// Constants
import {
  STARTING_PAGE,
  STARTING_PAGE_LIMIT,
} from "../../common/constants/tableConfig";
// Create program
export const createProgramAsync = (data,refetchParams) => (dispatch) => {
  ApiService.apiCall({
    url: ApiService.paths.programList.programsCreate,
    token: true,
    method: "POST",
    data: data,
  })
    .then((response) => {
      if (!response?.response?.status) {
        notify(`Program created!`);
        fetchProgramListBySessionAsync(dispatch,refetchParams)
      } else {
        notify(response.message, "warn");
      }
    })
    .catch((error) => {
      dispatch(
        fetchLoadingError({
          message: "Произошла ошибка во время создания трансляции",
        })
      );
      setTimeout(() => {
        dispatch(fetchLoadingClearError());
      }, 1000);
    });
};
// Edit program
export const updateProgramAsync = (data, refetchParams) => (dispatch) => {
  ApiService.apiCall({
    url: ApiService.paths.programList.programsUpdate,
    method: "PUT",
    token: true,
    data: data,
  })
    .then((response) => {

      if (!response?.response?.status) {
        notify(`Program updated!`, "success");
        fetchProgramListBySessionAsync(dispatch,refetchParams)
      } else {
        notify(response.message, "warn");
      }
    })
    .catch((error) => {
      dispatch(
        fetchLoadingError({
          message: "Произошла ошибка во время редактирования вопроса",
        })
      );
      setTimeout(() => {
        dispatch(fetchLoadingClearError());
      }, 1000);
    });
};
// Delete programs
export const deleteProgramAsync = (data, refetchParams) => (dispatch) => {
  ApiService.apiCall({
    url: ApiService.paths.programList.programsDelete,
    token: true,
    method: "DELETE",
    params: { id: data },
  })
    .then((response) => {
      if (!response?.response?.status || response?.data?.total === 0) {
        notify(`Program deleted!`, "success");
        fetchProgramListBySessionAsync(dispatch,refetchParams)
      } else {
        notify(response.message, "warn");
      }
    })
    .catch((error) => {
      dispatch(
        fetchLoadingError({
          message: "Произошла ошибка во время удаления трансляции",
        })
      );
      setTimeout(() => {
        dispatch(fetchLoadingClearError());
      }, 1000);
    });
};
// Get paginated ProgramList list - {page,limit}
const fetchProgramGetSuccess = (data) => ({
  type: ProgramListActionTypes.FETCH_GET_PROGRAM_LIST_BY_SESSION_SUCCESS,
  payload: data,
});
const fetchProgramGetByHallSuccess = (data) => ({
  type: ProgramListActionTypes.FETCH_GET_PROGRAM_LIST_BY_HALL_SUCCESS,
  payload: data,
});
const fetchProgramGetStart = () => ({
  type: ProgramListActionTypes.FETCH_GET_PROGRAM_LIST_START,
});
const fetchProgramGetError = () => ({
  type: ProgramListActionTypes.FETCH_GET_PROGRAM_LIST_ERROR,
});
const fetchProgramGetClearError = () => ({
  type: ProgramListActionTypes.FETCH_GET_PROGRAM_LIST_CLEAR_ERROR,
});

export const fetchProgramListBySessionAsync = (
  dispatch,
  params = { page: STARTING_PAGE - 1, limit: STARTING_PAGE_LIMIT }
) => {
  dispatch(fetchProgramGetStart());

  ApiService.apiCall({
    url: ApiService.paths.programList.programsGetBySessionId,
    token: true,
    params: { page: params.page + 1, limit: params.limit,sessionId:params.id },
  })
    .then((response) => {
      if (response.data.total === 0) {
        dispatch(
          fetchProgramGetSuccess({
            list: [],
            params: { page: 0, total: 0, limit: 5 },
          })
        );
      } else {
        const { programs, pages, total } = response?.data;
        dispatch(
          fetchProgramGetSuccess({
            list: programs,
            params: { limit: params.limit, pages, total },
          })
        );
      }
    })
    .catch((error) => {
      dispatch(fetchProgramGetError(error));
      setTimeout(() => {
        fetchProgramGetClearError();
      }, 300);
    });
};
export const clearCurrentList = () => ({
  type: ProgramListActionTypes.CLEAR_CURRENT_LIST,
});
export const fetchProgramListByHallIdAsync = (dispatch, hallId) => {
  dispatch(fetchProgramGetStart());

  ApiService.apiCall({
    url: ApiService.paths.programList.programsGetByHall,
    token: true,
    params: { hallId },
  })
    .then((response) => {
      dispatch(
        fetchProgramGetByHallSuccess({
          list: GetData.getProgramsFromBroadcasts(response.data),
        })
      );
    })
    .catch((error) => {
      dispatch(fetchProgramGetError(error));
      setTimeout(() => {
        fetchProgramGetClearError();
      }, 300);
    });
};
