// Types
import { ContentListTypes } from "../types";
// Services
import ApiService from "../../services/ApiService";
import notify from "../../services/Notifications";

const fetchContentsError=(data)=>({
  type:ContentListTypes.FETCH_GET_CONTENT_LIST_ERROR,
  payload:data
})
const fetchContentsClearError=()=>({
  type:ContentListTypes.FETCH_GET_CONTENT_LIST_CLEAR_ERROR
})
const fetchContentsGetSuccess = (data) => ({
  type: ContentListTypes.FETCH_GET_CONTENT_LIST_SUCCESS,
  fields: data,
});

const fetchContentsGetStart = () => ({
  type: ContentListTypes.FETCH_GET_CONTENT_LIST_START,
});

export const fetchContentsListAsync = (
  dispatch,
) => {

  dispatch(fetchContentsGetStart());

  ApiService.apiCall({
    url: ApiService.paths.contents.contentGet,
    token: true,
  })
    .then((response) => {
      if(response?.field) {
        dispatch(fetchContentsGetSuccess(response?.field))
      }
    })
    .catch((error) => {
      dispatch(fetchContentsError(error));
      setTimeout(() => {
        fetchContentsClearError();
      }, 300);
    });
};


export const updateContentsAsync = (data) => (dispatch) => {
  ApiService.apiCall({
    url: ApiService.paths.contents.contentEdit,
    token: true,
    method: "PUT",
    data: data,
  })
    .then(response => {
      if (response?.field?.status) {
          notify(`Данные успешно отредактированы!`, "success");
          fetchContentsListAsync(dispatch);
        } else {
          notify(response.message, "warn");
        }
    })
    .catch((error) => {
        dispatch(
          fetchContentsError({
            message: "Произошла ошибка во время редактирования полей",
          })
        );
        setTimeout(() => {
          dispatch(fetchContentsClearError());
        }, 1000);
      });
  };
