export const LoadingTypes = {
  LOADING_START: "LOADING_START",
  LOADING_FINISH: "LOADING_FINISH",
  LOADING_ERROR: "LOADING_ERROR",
  LOADING_CLEAR_ERROR: "LOADING_CLEAR_ERROR",
};
export const UserActionTypes = {
  CLEAR_USER_ERROR: "CLEAR_USER_ERROR",
  /* AUTH */
  FETCH_LOGIN_USER_START: "FETCH_LOGIN_USER_START",
  FETCH_LOGIN_USER_SUCCESS: "FETCH_LOGIN_USER_SUCCESS",
  FETCH_LOGIN_USER_ERROR: "FETCH_LOGIN_USER_ERROR",
  LOGOUT_USER: "LOGOUT_USER",
  /* CURRENT USER */
  FETCH_CURRENT_USER_START: "FETCH_CURRENT_USER_START",
  FETCH_CURRENT_USER_SUCCESS: "FETCH_CURRENT_USER_SUCCESS",
  FETCH_CURRENT_USER_ERROR: "FETCH_CURRENT_USER_ERROR",
};
export const UserListActionTypes = {
  FETCH_GET_USER_LIST: "FETCH_GET_USER_LIST",
  FETCH_GET_USER_EXCEL_LIST: "FETCH_GET_USER_EXCEL_LIST",
  SORT_USER_LIST: "SORT_USER_LIST"
};
export const QaListActionTypes = {
  FETCH_GET_QA_LIST: "FETCH_GET_QA_LIST",
};
export const EposterListActionTypes = {
  FETCH_GET_EPOSTER_LIST: "FETCH_GET_EPOSTER_LIST",
};
export const BroadcastListActionTypes = {
  FETCH_GET_BROADCAST_LIST: "FETCH_GET_BROADCAST_LIST",
};
export const ProgramListActionTypes = {
  CLEAR_CURRENT_LIST: "CLEAR_CURRENT_LIST",
  FETCH_GET_PROGRAM_LIST_START: "FETCH_GET_PROGRAM_LIST_START",
  FETCH_GET_PROGRAM_LIST_SUCCESS: "FETCH_GET_PROGRAM_LIST_SUCCESS",
  FETCH_GET_PROGRAM_LIST_ERROR: "FETCH_GET_PROGRAM_LIST_ERROR",
  FETCH_GET_PROGRAM_LIST_CLEAR_ERROR: "FETCH_GET_PROGRAM_LIST_CLEAR_ERROR",
  FETCH_GET_PROGRAM_LIST_BY_HALL_SUCCESS:
    "FETCH_GET_PROGRAM_LIST_BY_HALL_SUCCESS",
  FETCH_GET_PROGRAM_LIST_BY_SESSION_SUCCESS:
    "FETCH_GET_PROGRAM_LIST_BY_SESSION_SUCCESS",
};
export const SessionsListActionTypes = {
  CLEAR_CURRENT_LIST: "CLEAR_CURRENT_LIST",
  FETCH_GET_SESSIONS_LIST_START: "FETCH_GET_SESSIONS_LIST_START",
  FETCH_GET_SESSIONS_LIST_SUCCESS: "FETCH_GET_SESSIONS_LIST_SUCCESS",
  FETCH_GET_SESSIONS_LIST_ERROR: "FETCH_GET_SESSIONS_LIST_ERROR",
  FETCH_GET_SESSIONS_LIST_CLEAR_ERROR: "FETCH_GET_SESSIONS_LIST_CLEAR_ERROR",
};
export const ChatsActionTypes = {
  /** GET LIST */
  FETCH_GET_MESSAGE_LIST_START: "FETCH_GET_MESSAGE_LIST_START",
  FETCH_GET_MESSAGE_LIST_SUCCESS: "FETCH_GET_MESSAGE_LIST_SUCCESS",
  /** MESSAGE ERROR HANDLING */
  FETCH_MESSAGE_ERROR: "FETCH_GET_MESSAGE_LIST_ERROR",
  FETCH_CLEAR_ERROR: "FETCH_GET_PROGRAM_LIST_CLEAR_ERROR",
  /** DELETE MESSAGE */
  FETCH_DELETE_MESSAGE_START: "FETCH_DELETE_MESSAGE_START",
  FETCH_DELETE_MESSAGE_SUCCESS: "FETCH_DELETE_MESSAGE_SUCCESS",
};
export const PartnerListActionTypes = {
  CLEAR_CURRENT_PARTNER_DATA: "CLEAR_CURRENT_PARTNER_DATA",
  FETCH_GET_PARTNER_LIST: "FETCH_GET_PARTNER_LIST",
  FETCH_GET_PARTNER_BY_ID_START: "FETCH_GET_PARTNER_BY_ID_START",
  FETCH_GET_PARTNER_BY_ID_SUCCESS: "FETCH_GET_PARTNER_BY_ID_SUCCESS",
  FETCH_GET_PARTNER_BY_ID_ERROR: "FETCH_GET_PARTNER_BY_ID_ERROR",
  FETCH_GET_PARTNER_BY_ID_CLEAR_ERROR: "FETCH_GET_PARTNER_BY_ID_CLEAR_ERROR",
};
export const FileTypes = {
  FETCH_GET_FILES_SUCCESS: "FETCH_GET_FILES_SUCCESS",
};

export const DateListTypes = {
  FETCH_GET_DATES_LIST_START: "FETCH_GET_DATES_LIST_START",
  FETCH_GET_DATES_LIST_SUCCESS: "FETCH_GET_DATES_LIST_SUCCESS",
  FETCH_GET_DATES_LIST_ERROR: "FETCH_GET_DATES_LIST_ERROR",
  FETCH_GET_DATES_LIST_CLEAR_ERROR: "FETCH_GET_DATES_LIST_CLEAR_ERROR",
}

export const HallListTypes = {
  FETCH_GET_HALL_LIST_START: "FETCH_GET_HALL_LIST_START",
  FETCH_GET_HALL_LIST_SUCCESS: "FETCH_GET_HALL_LIST_SUCCESS",
  FETCH_GET_HALL_LIST_ERROR: "FETCH_GET_HALL_LIST_ERROR",
  FETCH_GET_HALL_LIST_CLEAR_ERROR: "FETCH_GET_HALL_LIST_CLEAR_ERROR",
}

export const ContentListTypes = {
  FETCH_GET_CONTENT_LIST_START: "FETCH_GET_CONTENT_LIST_START",
  FETCH_GET_CONTENT_LIST_SUCCESS: "FETCH_GET_CONTENT_LIST_SUCCESS",
  FETCH_GET_CONTENT_LIST_ERROR: "FETCH_GET_CONTENT_LIST_ERROR",
  FETCH_GET_CONTENT_LIST_CLEAR_ERROR: "FETCH_GET_CONTENT_LIST_CLEAR_ERROR",
}

export const MaterialsListTypes = {
  FETCH_GET_MATERIAL_LIST_START: "FETCH_GET_MATERIAL_LIST_START",
  FETCH_GET_MATERIAL_LIST_SUCCESS: "FETCH_GET_MATERIAL_LIST_SUCCESS",
  FETCH_GET_MATERIAL_LIST_ERROR: "FETCH_GET_MATERIAL_LIST_ERROR",
  FETCH_GET_MATERIAL_LIST_CLEAR_ERROR: "FETCH_GET_MATERIAL_LIST_CLEAR_ERROR",
}


