// Types
import { HallListTypes } from "../types";
// Services
import ApiService from "../../services/ApiService";
import notify from "../../services/Notifications";

const fetchHallsError=(data)=>({
  type:HallListTypes.FETCH_GET_HALL_LIST_ERROR,
  payload:data
})
const fetchHallsClearError=()=>({
  type:HallListTypes.FETCH_GET_HALL_LIST_CLEAR_ERROR
})
const fetchHallsGetSuccess = (data) => ({
  type: HallListTypes.FETCH_GET_HALL_LIST_SUCCESS,
  halls: data,
});

const fetchHallsGetStart = () => ({
  type: HallListTypes.FETCH_GET_HALL_LIST_START,
});


export const createHallAsync = (data) => dispatch => {
  ApiService.apiCall({
    url: ApiService.paths.halls.hallCreate,
    token: true,
    method: "POST",
    data: data,
  })
    .then(response => {
      if (response?.data?.status) {
        notify(`Зал успешно создан!`, "success");
        fetchHallListAsync(dispatch);
      } else {
        notify(response.message, "warn");
      }
    })
    .catch((error) => {
      dispatch(
        fetchHallsError({
          message: "Произошла ошибка во время создания зала",
        })
      );
      setTimeout(() => {
        dispatch(fetchHallsClearError());
      }, 1000);
    });
}

export const fetchHallListAsync = (
  dispatch,
) => {

  dispatch(fetchHallsGetStart());

  ApiService.apiCall({
    url: ApiService.paths.halls.hallGet,
    token: true,
  })
    .then((response) => {
      if(response?.data) {
        dispatch(fetchHallsGetSuccess(response?.data.halls))
      }
    })
    .catch((error) => {
      dispatch(fetchHallsError(error));
      setTimeout(() => {
        fetchHallsClearError();
      }, 300);
    });
};

export const deleteHallAsync = (data) => dispatch => {
  ApiService.apiCall({
    url: ApiService.paths.halls.hallDelete,
    token: true,
    method: "DELETE",
    params: { hallId: data }
  })
   .then(response => {
    if (response?.data?.status) {
        notify(`Зал успешно удален!`, "success");
        fetchHallListAsync(dispatch);
      } else {
        notify(response.message, "warn");
      }
   })
   .catch((error) => {
      dispatch(
        fetchHallsError({
          message: "Произошла ошибка во время удаления зала",
        })
      );
      setTimeout(() => {
        dispatch(fetchHallsClearError());
      }, 1000);
    });
}

export const updateHallAsync = (data) => (dispatch) => {
  ApiService.apiCall({
    url: ApiService.paths.halls.hallEdit,
    token: true,
    method: "PUT",
    data: data,
  })
    .then(response => {
      if (response?.data?.status) {
          notify(`Зал успешно отредактирован!`, "success");
          fetchHallListAsync(dispatch);
        } else {
          notify(response.message, "warn");
        }
    })
    .catch((error) => {
        dispatch(
          fetchHallsError({
            message: "Произошла ошибка во время редактирования зала",
          })
        );
        setTimeout(() => {
          dispatch(fetchHallsClearError());
        }, 1000);
      });
  };
